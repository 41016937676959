
    var dom = document.createElement('template');
    dom.innerHTML = `<div class="all-snapshots">
  <h2>
    <i class="material-icons back">arrow_back</i>
    <p>All snapshots</p>
  </h2>
  <ol>
  </ol>
</div>
`;
    module.exports = dom;
  