
    var dom = document.createElement('template');
    dom.innerHTML = `<div class="snapshot-item">
  <h3></h3>
  <div class="created-at">
    <h4 class="snapshot-info">created</h4>
    <p class="snapshot-info"></p>
  </div>
  <div class="archived-at">
    <h4 class="snapshot-info">archived</h4>
    <p class="snapshot-info"></p>
  </div>
</div>
`;
    module.exports = dom;
  